<template>
  <div
    v-if="$showDevThings"
    :class="['dev-layer-activator', {
      'dev-layer-activator--white-page': _.get($route.meta, 'layout.isWhite'),
      'dev-layer-activator--two-pane-layout': _.get($route.meta, 'layout.isTwoPane'),
      'sidebar-is-collapsed': isSidebarCollapsed,
    }]">
    <CyButton
      theme="grey"
      variant="tertiary"
      :class="['dev-layer-activator__btn', {
        'dev-layer-activator__btn--show-warn-blip': hasMockedEndpoints,
        'dev-layer-activator__btn--show-error-blip': hasTooManyPermCalls,
      }]"
      @click.native="() => TOGGLE_DEV_LAYER(true)">
      <CyTooltip
        :disabled="!isSidebarCollapsed"
        right>
        <template #activator="{ on }">
          <v-icon
            class="dev-layer-activator__btn-icon"
            size="34"
            v-on="on">
            monitor_heart_rounded
          </v-icon>
        </template>
        <span class="tooltip-text">🦄 Open dev layer ✨</span>
        <span
          v-if="hasTooManyPermCalls"
          class="tooltip-text--error">
          Too many permission calls!
        </span>
        <span
          v-if="hasMockedEndpoints"
          class="tooltip-text--warn">
          This page has mocked endpoints
        </span>
      </CyTooltip>
      <span
        v-if="!isSidebarCollapsed"
        class="dev-layer-activator__btn-text">
        🦄 Dev Layer
      </span>
    </CyButton>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CyDevLayerActivatorBtn',
  props: {
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('dev', [
      'hasMockedEndpoints',
      'hasTooManyPermCalls',
    ]),
  },
  methods: {
    ...mapMutations('dev', [
      'TOGGLE_DEV_LAYER',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.dev-layer-activator {
  display: flex;
  position: relative;
  flex: 0 1 auto;
  justify-content: center;
  width: 100%;
  border: none;

  &--white-page {
    position: absolute;
    top: 25px;
    left: 40px;
    width: auto;
  }

  &__btn.cy-btn.v-btn.v-btn {
    z-index: 5;
    flex: 0 1 auto;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding: 6px;
    border: none;
    border-radius: 8px;
    background: none;
    box-shadow: none;

    .dev-layer-activator__btn-icon.v-icon.v-icon.v-icon {
      width: 1em;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &.cy-btn__variant--tertiary:hover {
      @extend %rainbow-background;
    }
  }

  &__btn {
    color: var(--menu-text-color) !important;

    &-text {
      margin-left: 6px;
    }

    &--show-error-blip,
    &--show-warn-blip {
      &::after {
        content: "";
        position: absolute;
        right: 2px;
        bottom: 2px;
        width: 6px;
        height: 6px;
        border-radius: 1em;
      }
    }

    &--show-error-blip::after {
      background: get-color("error");
    }

    &--show-warn-blip::after {
      background: get-color("warning");
    }
  }

  &.sidebar-is-collapsed {
    .dev-layer-activator {
      &__btn.cy-btn.v-btn.v-btn {
        justify-content: center;
        width: 40px;
      }
    }
  }

  &--two-pane-layout {
    @media screen and (max-width: 745px) {
      top: 4px;
      width: auto;
    }
  }
}

.tooltip-text {
  &--error {
    color: get-color("error", "light-1");
  }

  &--warn {
    color: get-color("warning");
  }
}
</style>
